import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  EmailTemplateDto,
  getEnumOptions,
  humanizeCasedString,
  isNullObj,
  ModuleKeywords,
} from '@shared/classes';
import { KendoEditorDropdownToolbarData } from '@shared/components/ui/input-fields/kendo-editor/kendo-editor.component';
import { ViewModeService } from '@shared/services';
import { EmailTemplatesDataService } from '../../services/data/email-templates-data.service';

@Component({
  selector: 'app-email-template-item-form',
  templateUrl: './email-template-item-form.component.html',
  styleUrls: ['./email-template-item-form.component.scss'],
})
export class EmailTemplateItemFormComponent extends BaseForm<EmailTemplateDto> implements OnInit {
  applyToList = getEnumOptions(EmailTemplateDto.ApplyToEnum);
  templateTypeList = getEnumOptions(EmailTemplateDto.TemplateTypeEnum);
  contextVariables: any[] = [];
  customDropdownData: KendoEditorDropdownToolbarData[] = [];
  invalidFormDropdownData: KendoEditorDropdownToolbarData[] = [{ text: 'Please Select a Template Type', value: null }];
  loadingDropdownData: KendoEditorDropdownToolbarData[] = [{ text: 'Please wait...', value: null }];
  get currentCustomDropdownData() {
    if (!this.formGroup?.controls?.templateType?.value) {
      return this.invalidFormDropdownData;
    }
    if (
      !this.isLoadingContextSuggestions &&
      this.customDropdownData?.length > 0 &&
      !!this.formGroup?.controls?.templateType?.value
    ) {
      return this.customDropdownData;
    } else {
      return this.loadingDropdownData;
    }
  }
  constructor(
    public viewModeService: ViewModeService,
    private requestService: EmailTemplatesDataService
  ) {
    super(viewModeService, ModuleKeywords.EmailTemplate);
  }

  ngOnInit(): void {
    // this.loadContextSuggestions();
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }
  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    if (this?.data?.isDefault) {
      this.formGroup?.controls?.templateType?.disable();
      this.formGroup?.controls?.applyTo?.disable();
    } else {
      this.formGroup?.controls?.templateType?.enable();
      this.formGroup?.controls?.applyTo?.enable();
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      templateType: new FormControl(null, Validators.required),
      applyTo: new FormControl(null, Validators.required),
      // systemGenerated: new FormControl({value:false,disabled:true}),
      subjectTemplate: new FormControl(null, Validators.required),
      bodyTemplate: new FormControl(null),
      // isDefault: new FormControl(false),
    });
  }
  isLoadingContextSuggestions = false;
  loadContextSuggestions() {
    const currentData = this.formGroup?.getRawValue();
    if (isNullObj(currentData?.templateType)) return;
    this.isLoadingContextSuggestions = true;
    this.requestService
      .getContextValues({
        params: { emailTemplateType: currentData?.templateType, type: currentData?.applyTo },
        showLoading: false,
        showMsg: false,
        extras: { cacheKey: `emailContext_${currentData?.templateType}_${currentData?.applyTo}` },
      })
      .subscribe({
        next: (res) => {
          // this.contextVariables = getOptionsFromStringArray(res);
          this.contextVariables = res.map((item) => {
            return {
              label: humanizeCasedString(item),
              // icon: 'pi pi-refresh',
              command: () => {
                this.onSelectOption(`$${item}`);
              },
            };
          });
          this.customDropdownData = res.map((item) => {
            return {
              text: humanizeCasedString(item),
              value: `$${item}`,
            };
          });
          this.isLoadingContextSuggestions = false;
        },
        error: (err) => {
          this.isLoadingContextSuggestions = false;
        },
      });
  }
  currentEditorSelectionIndex = 0;
  currentEditor = null;
  onEditorContextMenuClick(event, editor) {
    // const selection = editor?.quill?.getSelection();
    // this.currentEditorSelectionIndex = selection?.index || 0;
    // // editor?.quill?.insertText(selection.index, 'ABCD');
    // this.currentEditor = editor;
    this.loadContextSuggestions();
  }
  onSelectOption(option) {
    if (this.currentEditor) {
      // const selection = this.currentEditor?.quill?.getSelection();
      // this.currentEditor?.quill?.insertText(selection?.index || this.currentEditorSelectionIndex, option);
    }
  }
}
