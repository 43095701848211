<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <!-- <div class="col-6 md:col-3">
    <app-switch-input
      [label]="'Is Default'"
      [name]="'isDefault'"
      [control]="formGroup?.controls?.isDefault"
      [viewMode]="fieldViewMode"
      >
    </app-switch-input>
  </div>
  <div class="col-6 md:col-3">
    <app-switch-input
      [label]="'System Generated'"
      [name]="'systemGenerated'"
      [control]="formGroup?.controls?.systemGenerated"
      [viewMode]="fieldViewMode"
      >
    </app-switch-input>
  </div> -->
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Apply To'"
        [placeholder]="'Select Item'"
        [name]="'applyTo'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="applyToList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.applyTo"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Template Type'"
        [placeholder]="'Select Item'"
        [name]="'templateType'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="templateTypeList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.templateType"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12">
      <app-text-input
        [rows]="2"
        [name]="'subjectTemplate'"
        [label]="'Subject Template'"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.subjectTemplate"
        [viewMode]="fieldViewMode"
      ></app-text-input>
    </div>
    <!-- <div class="col-12">
  <app-text-input
    [rows]="8"
    [name]="'bodyTemplate'"
    [label]="'Body Template'"
    [placeholder]="'Enter Text...'"
    [control]="formGroup?.controls?.bodyTemplate"
    [viewMode]="fieldViewMode"
  ></app-text-input>
</div> -->
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'bodyTemplate'"
        [label]="'Body Template'"
        [placeholder]="'Enter Body Template...'"
        [control]="formGroup?.controls?.bodyTemplate"
        [viewMode]="fieldViewMode"
        [advanced]="true"
        [sunEditorMode]="false"
        [customDropdownData]="currentCustomDropdownData"
        (onCustomDropdownOpen)="loadContextSuggestions()"
      >
        <ng-template let-editor #toolbarActions>
          <button
            type="button"
            class=""
            pTooltip="Context Values"
            tooltipPosition="top"
            aria-label="Context Values"
            (click)="typesOp.toggle($event); onEditorContextMenuClick($event, editor)"
          >
            <i class="text-700 text-lg font-bold pi pi-hashtag"></i>
          </button>
        </ng-template>
      </app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
<p-overlayPanel #typesOp [showCloseIcon]="true">
  <ng-template pTemplate>
    @if (!formGroup?.controls?.templateType?.value) {
      <p>Please Fill Form First</p>
    }
    @if (!isLoadingContextSuggestions && contextVariables && !!formGroup?.controls?.templateType?.value) {
      <p-menu [style]="{ width: '15rem', height: '25rem', overflow: 'auto' }" [model]="contextVariables"></p-menu>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    <!-- <p-listbox [options]="contextVariables"
    [checkbox]="false"
    [filter]="true"
    [multiple]="false"
    optionLabel="value"
    [optionValue]="'value'"
    [style]="{ width: '15rem',height:'25rem',overflow:'auto' }">
  </p-listbox> -->
  </ng-template>
</p-overlayPanel>
